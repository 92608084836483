import { useMutation } from "@apollo/client";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, Tooltip } from '@mui/material';
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import IosShareIcon from '@mui/icons-material/IosShare';
import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { ADD_Tasklist, GET_ALLTASKLIST } from "GraphQL/Tasklists";

export default function AddTasklist(props) {
  let setCallback = props.onSet || function (newEntry) { console.log(newEntry) };
  const [CreateList, { loading, error}] = useMutation(ADD_Tasklist);
  const [open, setOpen] = useState(false);
  const [elName, setName] = useState("");
  const [isValidName, setIsValidName] = useState(false);
  ;
 
  const { t } = useTranslation("admin");

  const handleClickOpen = () => {
    setIsValidName(false);
    setName("");
    setOpen(true);
  };
  const handleClickExport = () => {
    console.log("Export DeKaenToli");
  };

  const handleNameChange = (event) => {
    const name = event;
    setName(name); 
    setIsValidName(name.trim().length > 2); 
    if (name.length < 3) return false;
    return true; 
  };

  const close = () => {
    setOpen(false);
  };

  
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && isValidName) {
      save();
    }
  };

  function save() {
    if (isValidName) {
      CreateList({
        variables: {
          name: elName
        },
        refetchQueries: [{ query: GET_ALLTASKLIST }],
        onCompleted: (data)=>{
          if(!error){
            setOpen(false);
            setCallback(data.newEntry);
            setName("");
          }
        }
      });

    }
  }

  if (loading) return <p>Loading...</p>;

  return (
    <div style={{ position: 'relative', zIndex: 99999 }}>
         <span style={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip title={t("dialog.newtasklist.addtask")}>
        <Button variant="light" onClick={handleClickOpen}>
          <NoteAddIcon />
        </Button>
      </Tooltip>
      <Tooltip title={t("dialog.newtasklist.exportask")}>
        <Button variant="light" onClick={handleClickExport}>
          <IosShareIcon />
        </Button>
      </Tooltip>
    </span>
      <Dialog open={open} onClose={close} fullWidth>
        <DialogTitle>{t("dialog.newjournal.title")}</DialogTitle>
        <DialogContent dividers>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              label={t("dialog.newtasklist.lblname")}
              placeholder={t("dialog.newtasklist.lblname")}
              value={elName}
              onChange={(e) => handleNameChange(e.target.value)}
              autoFocus
              onKeyDown={handleKeyDown}
            />
           
          </Box>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="contained" onClick={close}>{t("dialog.btncancel")}</Button>
          <Button size="small" disabled={!isValidName} variant="contained" onClick={() => { save(); }}> {t("dialog.btnsave")}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
 /*  return (
    <Provider theme={defaultTheme}>
      <DialogTrigger>
      <ActionButton  onPress={handleClickOpen}>
          <Add />
        </ActionButton>
        {(close) => (
          <Dialog>
            <Heading>
              <Flex alignItems="center" gap="size-100">
                <Text>
                {t("dialog.newtasklist.title")}
                </Text>
              </Flex>
            </Heading>
            <Divider />
            <Content>
              <Form onSubmit={onSubmit}>
                <TextField
                  isRequired necessityIndicator="icon"
                  label={t("dialog.newtasklist.lblname")}
                  autoFocus
                  value={elName}
                  onChange={(event) => handleNameChange(event)}
                />
              </Form>
            </Content>
              <ButtonGroup>
              <Button variant="secondary" onPress={close}>{t("dialog.btncancel")}</Button>
              <Button variant="accent" type="submit" onPress={() => { save(); close(); }} isDisabled={!isValidName}>{t("dialog.btnsave")}</Button>
            </ButtonGroup>
          </Dialog>
        )}
      </DialogTrigger>
    </Provider>
    /*   <Modal 
        opened={open} 
        onClose={() => setOpen(false)}
        onKeyDown={handleKeyDown}
        title={t("dialog.newtasklist.title")}
        size="xl"  
        centered
        overlayProps={{
          color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
        >
        <TextInput 
         label={t("dialog.newtasklist.lblname")}
         placeholder={t("dialog.newtasklist.lblname")}
         type="name"
         onChange={(e) => setName(e.target.value)}
         data-autofocus
         withAsterisk
         mx={"sm"}
         m={"sm"}      
         />
          {(loading) ? <Modal>Submitting...</Modal>:null}
          {(error) ? <Modal>Submission error!<br/>{error.message}</Modal> :null}
          <Group mt="xl">
          <Button  mx={"xl"} onClick={close}>{t("dialog.btncancel")}</Button>
          <Button  mx={"xl"} onClick={save} disabled={!isValidName()}>{t("dialog.btnsave")}</Button>
        </Group>
      </Modal>
    </React.Fragment> */
  
}
