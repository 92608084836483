import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { IconButton, Toolbar, Typography, Drawer } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'
import MenuDrawer from './MenuDrawer';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


export default function STNGAppBar(props){
  const { t } = useTranslation();
  const [state, setState] = React.useState(false);

  return (
    <React.Fragment>
      <Box>
        <AppBar position='static'>
          <Toolbar >
            <IconButton
              size='large'
              edge='start'
              color='inherit'
              aria-label='menu'
              onClick={() => setState(true)}
            >       <MenuIcon />
            </IconButton>
            <Typography 
            variant="h6" 
            component="div" 
            noWrap      
            style={{ flex: 1, textAlign: "center" }}
            >
                {t("app_title")}
              </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <Drawer
        anchor='left'
        open={state}
        onClose={() => setState(false)}
      >
        <MenuDrawer drawer_close={()=>setState(false)}/>
      </Drawer>
    </React.Fragment>
  );
}
