import * as React from 'react';
import GetProject from "../components/GsVb/projectmanager/ProManager"
import { useLoaderData } from "react-router-dom";

export function loader({params}){};

export default function Projectedit() {
    const id = useLoaderData();
    return (
        <React.Suspense fallback={<div>loading...</div>}>
            <GetProject/>
        </React.Suspense>
    );
}