import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import CREATE_ENTRY from "GraphQL/AddMaxEntryFullVariablesTask";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import 'dayjs/locale/de';
import { LOAD_ENTRIEST } from "GraphQL/Tasklists";
import { Tooltip } from "@mui/material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import moment from "moment";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export default function AddEntry(tasklistid) {

  const [name, setName] = useState("");
  const [responsible, setResponsible] = useState("");
  const [recipient, setrecipient] = useState("");
  const [content, setContent] = useState("");
  const [priority, setPrio] = useState("MEDIUM");
  const [status, setStatus] = useState("OPEN");
  const [finishdate, setfinishdate] = useState();
  const [open, setOpen] = useState(false);
  //const [inputValue, setInputValue] = useState('');

  const { t } = useTranslation("tasklist");
  const isButtonDisabled = name.length < 1 || content.length < 1;

  const sendValue = (e) => {
    if (priority === "") {
      setPrio("MEDIUM");
    }
    let duedate;
    if (finishdate !== "") {
      const originalDate = new Date(finishdate);
      const momentObject = moment(originalDate);
      duedate = momentObject.format("YYYY-MM-DDTHH:mm");
    } else {
      duedate = null;
    }

    // if (duedate === "") console.log("date vide")
    if (duedate === "") {
      addjournalentry({
        variables: {
          name: name,
          content: content,
          tasklistid: window.journalid,
          createdBy: name,
          priority: priority,
          status: status,
          responsible: responsible,
          recipient: recipient,
        },
        refetchQueries: [
          { query: LOAD_ENTRIEST, variables: { tasklist: window.journalid } },
        ],
      });
    } else {
      addjournalentry({
        variables: {
          name: name,
          content: content,
          tasklistid: window.journalid,
          createdBy: name,
          priority: priority,
          status: status,
          responsible: responsible,
          recipient: recipient,
          due: duedate,
        },
        refetchQueries: [
          { query: LOAD_ENTRIEST, variables: { tasklist: window.journalid } },
        ],
      });
    }
    setOpen(false);
  };

  const Priorities = [
    {
      value: "MEDIUM",
      label: t("materialreacttable.MEDIUM"),
    },
    {
      value: "HIGH",
      label: t("materialreacttable.HIGH"),
    },
    {
      value: "LOW",
      label: t("materialreacttable.LOW"),
    },
  ];
  const statuss = [
    {
      value: "OPEN",
      label: t("materialreacttable.open"),
    },
    {
      value: "FINISHED",
      label: t("materialreacttable.finished"),
    },
    {
      value: "ARCHIVED",
      label: t("materialreacttable.archived"),
    },
  ];

  const handleClickOpen = () => {
    setContent("");
    setName("");
    setPrio("MEDIUM");
    setStatus("OPEN");
    setResponsible("");
    setrecipient("");
    setfinishdate("");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [addjournalentry, { loading, error }] = useMutation(CREATE_ENTRY);

  if (loading) return "Submitting...";
  if (error) return `Submission error! ${error.message}`;

  const enabled = window.journalid !== undefined;



  return (


    <div style={{ position: 'relative', zIndex: 99999 }}>
      <Tooltip title={t("addentry.btnaddentry")}>
        <span>
          <Button disabled={!enabled} variant="light" onClick={handleClickOpen}><NoteAddIcon /></Button>
        </span>
      </Tooltip>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
        <Dialog open={open} onClose={handleClose} fullWidth >
          <DialogTitle>{t("addentry.btnaddentry")}</DialogTitle>
          <DialogContent dividers>
            <Box display="flex" flexDirection="column" gap={2}>
              <TextField
                label={t("modal.lblcreatorname")}
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoFocus
                size="small"
                margin="dense"
                variant="filled"
                required
              />
              <TextField
                label={t("modal.lblcontent")}
                value={content}
                onChange={(e) => setContent(e.target.value)}
                multiline
                minRows={5}
                maxRows={6}
                radius="md"
                variant="filled"
                required
              />
              <Button
                onClick={() => setContent(t("modal.aeiou"))}
                variant="contained"
                sx={{ width: '20%' }}
              >
                {t("modal.btnaeiou")}
              </Button>
              <TextField
                label={t("modal.lblresponsible")}
                value={responsible}
                onChange={(e) => setResponsible(e.target.value)}
                variant="filled"

              />
              <TextField
                label={t("modal.lblrecipient")}
                value={recipient}
                onChange={(e) => setrecipient(e.target.value)}
                variant="filled"

              />
              < Box display="flex" flexDirection="row" gap={2}>
                <FormControl fullWidth margin="dense" size="small" sx={{ width: '50%' }}>
                  <InputLabel>{t("modal.status")}</InputLabel>
                  <Select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    label={t("modal.status")}
                    variant="filled"
                  >
                    {statuss.map((status, index) => (
                      <MenuItem key={index} value={status.value}>
                        {status.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth margin="dense" size="small" sx={{ width: '50%' }}>
                  <InputLabel>{t("modal.priority")}</InputLabel>
                  <Select
                    value={priority}
                    onChange={(e) => setPrio(e.target.value)}
                    label={t("modal.priority")}
                    variant="filled"
                  >
                    {Priorities.map((priority, index) => (
                      <MenuItem key={index} value={priority.value}>
                        {priority.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <div>
                <DateTimePicker label={t("modal.date")}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}

                  onChange={(newValue) => setfinishdate(dayjs(newValue))} // Ensure newValue is converted to dayjs object
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Box>

          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose}>{t("modal.cancel")}</Button>
            <Button disabled={isButtonDisabled} variant="contained" onClick={() => { sendValue(); }}>{t("modal.save")}</Button>
          </DialogActions>
        </Dialog>
      </LocalizationProvider>
    </div>
  );
}
