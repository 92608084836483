import { ReactSVG } from "react-svg";
import React from "react";
import './ProcessSVG.css';
import { Menu, MenuItem,Tooltip,Typography } from "@mui/material";
import { useMutation, useQuery} from "@apollo/client";
import { SET_PROCESSITEM, GET_PROCESSITEMS_BY_OPERATIONIDTYPE } from "GraphQL/Process";
import { useTranslation } from "react-i18next";

export default function ProcessSVG(props) {
    let op = props.op;
    let proc = props.process;
    let onSetFolderviews = props.onSetFolderviews || function (fv) { console.log(fv) };
    const [contextMenu, setContextMenu] = React.useState(null);
    const { t } = useTranslation("process");

    const [setProcessItem] = useMutation(SET_PROCESSITEM, {
        refetchQueries: [
            GET_PROCESSITEMS_BY_OPERATIONIDTYPE
        ],
      });
    const { loading, error, data } = useQuery(GET_PROCESSITEMS_BY_OPERATIONIDTYPE, { variables: { op: op.id }, pollInterval: 15000 });

    let itemstates = [];
    if(data){
        data.getProcessItemByOperationIDType.map(item => itemstates[item.id]=item.state);
    }

    let source = proc.path; 
    if (process.env.NODE_ENV === 'development') {
        source = "https://localhost:8080" + source;
    }

    let folderviews = [];
    

    function afterInjection(svg) {
        //handle folderviews
        if (folderviews.length === 0) {
            console.debug("SVG loaded, parsing...")
            //Check if <defs> ist present
            let defs = svg.getElementsByTagName("defs")[0]
            if (defs) {
                //check if <defs> contains <pft:folderviews>
                let folderviewsxml = defs.getElementsByTagName("pft:folderviews")[0]
                if (folderviewsxml) {
                    //parse folderviews
                    for (const folderview of folderviewsxml.getElementsByTagName("pft:folder")) {
                        let folder = { path: folderview.getAttribute("path"), size: folderview.getAttribute("size"), name: folderview.getAttribute("name") };
                        folderviews.push(folder);
                    }
                } else {
                    console.log("no <pft:folderviews> found");
                }
                console.debug("Set folderviews: ", folderviews);
                onSetFolderviews(folderviews);
            } else {
                console.log("no <defs> found");
            }
        }

        //handle items
        let items = svg.getElementsByTagName("pft:itemdata");
        for (const item of items) {
            let parent = item.parentNode;
            parent.setAttribute("state", itemstates[item.getAttribute("id")]);
            parent.setAttribute("id", item.getAttribute("id"));
            let url = proc.clientPath + item.getAttribute("path");
            parent.setAttribute("onclick", "location.href='"+url.replace(/'/g,"%27")+"';")
            parent.setAttribute("style", "cursor: pointer;");
        }
    }

    function handleContext(e) {
        e.preventDefault();
        let target = e.target.parentNode.id
        if(target === "") return;
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: e.clientX + 2,
                    mouseY: e.clientY - 6,
                    target: target
                } :
                null,
        );
    }

    function handleClose(processState) {
        setContextMenu(null);
        setProcessItem({variables: {op: op.id, id: contextMenu.target, state: processState}})
    };

    if (!proc) return (null);

    console.log("Folderviews: ", folderviews);
    console.log("Folderviews len: ", folderviews.length);
    return (
        <div onContextMenu={handleContext} style={{ cursor: 'context-menu'}} className="svg-container">
            <ReactSVG src={source} beforeInjection={afterInjection}/>
            {(loading) ? <Typography>loading states...</Typography> : null}
            {(error) ? <Typography>loading states error!</Typography> : null}
            <Menu
                open={contextMenu !== null}
                onClose={(e)=>setContextMenu(null)}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
            >
                <Tooltip title={t("tooltip_new")} placement="right-start"><MenuItem onClick={e => handleClose("NEW")}>{t("new")}</MenuItem></Tooltip>                
                <Tooltip title={t("tooltip_done")} placement="right-start"><MenuItem onClick={e => handleClose("DONE")}>{t("done")}</MenuItem></Tooltip>
                <Tooltip title={t("tooltip_inProcess")} placement="right-start"><MenuItem onClick={e => handleClose("INPROCESS")}>{t("inProcess")}</MenuItem></Tooltip>
                <Tooltip title={t("tooltip_overdue")} placement="right-start"><MenuItem onClick={e => handleClose("OVERDUE")}>{t("overdue")}</MenuItem></Tooltip>
                <Tooltip title={t("tooltip_approved")} placement="right-start"><MenuItem onClick={e => handleClose("APPROVED")}>{t("approved")}</MenuItem></Tooltip>
            </Menu>
        </div>
    )
}
