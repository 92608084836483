import * as React from 'react';
import MainEdit from "../components/GsVb/projectmanager/Projecteditfield";

import { useLoaderData } from "react-router-dom";

export function loader({params}){if("id" in params)return params.id;return null;}

export default function PromanagerIdPage() {
    const id = useLoaderData();
    return (
        <React.Suspense fallback={<div>loading...</div>}>
            <MainEdit id={id}/>
        </React.Suspense>
    );
}