import React, { useMemo } from "react";
import { MaterialReactTable, useMaterialReactTable, } from 'material-react-table';
import { darken, lighten, useTheme } from '@mui/material';
import { MetroSpinner } from "react-spinners-kit";
//Import Material React Table Translations
import { MRT_Localization_FR } from "material-react-table/locales/fr";
import { MRT_Localization_DE } from "material-react-table/locales/de";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { Link } from 'react-router-dom';
import { useQuery } from "@apollo/client";
import "jspdf-autotable";
import { Button, Box } from '@mui/material';
import { useTranslation } from "react-i18next";
import { GET_PROJECT } from "GraphQL/ProjectManager";
import { differenceInDays } from 'date-fns';



const GetProject = (refresh) => {
  //**************************** DECLARE CONST *****************************************/
  const theme = useTheme();
  const baseBackgroundColor =
    theme.palette.mode === 'dark'
      ? 'rgba(240, 240, 240, 1)'
      : 'rgba(255, 255, 255, 1)';

  const { t, i18n } = useTranslation("proman");
  let MRT_language = "";

  if (i18n.language === "fr") {
    MRT_language = MRT_Localization_FR;
  }
  if (i18n.language === "de") {
    MRT_language = MRT_Localization_DE;
  }
  if (i18n.language === "en") {
    MRT_language = MRT_Localization_EN;
  }

  const { loading, error, data } = useQuery(GET_PROJECT, {
    pollInterval: 60000,
  });

  console.log("Data", data);

  // ******************************************** Define Colums for Table *************************************************************

  const generateColumn = (id) => ({
    id,
    header: id.toUpperCase(),
    multiline: true,
    maxsize: 10,
    size: 5,
    enableSorting: true,
    Cell: ({ row }) => {
      let borderstatus = '0px';
      const cellValue = row.original[id];
      let backgroundColor = cellValue.state ? cellValue.state.toLowerCase() : 'default';
      let color = backgroundColor === 'none' ? 'white' : 'black';
      const text = cellValue.txt === null ? '      ' : cellValue.txt;  // replace 'default text' with your desired value
      if (text === '      ') {
        color = row.original[id].state;  // reassign value to color
        borderstatus = '30px';
      }
      if (backgroundColor === 'green') {
        backgroundColor = '#77DD77';  // set background color to #77DD77 if it is green
      }
      if (backgroundColor === 'yellow') {
        backgroundColor = '#FDFD96';  // set background color to #FDFD96 if it is yellow
      }
      if (backgroundColor === 'red') {
        backgroundColor = '#FF6961';  // set background color to #FF6961 if it is red

      }
      return (
        <div style={{ backgroundColor, color, whiteSpace: 'pre-wrap', width: '100%', height: '100%', borderRadius: borderstatus }}>
          {cellValue.txt || text}
        </div>
      );
    },
    muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
      variant: "outlined",
      multiline: true,
    }),
    getCellStyle: (cellInfo) => {
      const cellValue = cellInfo.row.original[id];
      if (cellValue.state) {
        return { backgroundColor: cellValue.state.toLowerCase() };
      }
      return {};
    },
  });


  const tableData = useMemo(() => {
    if (data && data.allProjectEntry) {
      return data.allProjectEntry;
    }
    return [];
  }, [data]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "cell",
        header: "Cell",
        enableClickToCopy: false,
      },
      {
        id: "name",
        header: "Name",
        Cell: ({ row }) => {
          if (!data) {
            return `${row.original.projectNo} ${row.original.name}`;
          }
          const entry = data.allProjectEntry.find(entry => entry.id === row.original.id);
          if (entry) {
            return <Link to={`/ProjectEdit/${entry.id}`}>{`${row.original.projectNo} - ${row.original.name}`}</Link>;
          }
          return `${row.original.projectNo} ${row.original.name}`;
        },
        enableClickToCopy: false,
        enableEditing: false,

      },
      {
        accessorKey: "description",
        header: t("Description"),
        enableEditing: false,
        enableClickToCopy: false,
        size: 0.1,
        Cell: ({ cell }) => (
          <span style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>
            <div>
              {cell.renderValue()}
            </div>
          </span>
        ),
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          variant: "outlined",
          multiline: true,
        }),
      },
      {
        accessorKey: "projectLead",
        header: "projectLead",
        style: { whiteSpace: "wrap" },
        filterVariant: 'autocomplete',
      },
      {
        accessorKey: "lastUpdate",
        header: t("tablelibrary.lastupdate"),
        Cell: (row) => {
          //Format  Date from ISO to DD.MM.YY HH:mm
          const value = row.renderedCellValue;
          if (!value) return '';
          const date = new Date(value);
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const year = String(date.getFullYear()).slice(2);
          const hours = String(date.getHours()).padStart(2, '0');
          const minutes = String(date.getMinutes()).padStart(2, '0');
          return `${day}.${month}.${year} ${hours}:${minutes}`;
        },
        enableEditing: false,
        enableClickToCopy: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "due",
        header: "due",
        Cell: ({ cell, row }) => {
          const value = new Date(row.original.due);
          const Today = new Date();
          const diffDays = differenceInDays(value, Today);
          console.log("diffDays", diffDays);
          return (
            <Box
              component="span"
              sx={() => ({
                backgroundColor:
                  diffDays <= -1
                    ? "#FF6961" // Red
                    : diffDays <= 7
                      ? "#FDFD96" // Yellow
                      : diffDays >= 8
                        ? "#77DD77" // Green
                        : "#FF6961", // Default to Red if none of the conditions match
                borderRadius: "0.25rem",
                color: "#000",
                maxWidth: "18ch",
                p: "0.25rem",
              })}
            >
              {cell.getValue().toLocaleDateString()}
            </Box>
          );
        },
        accessorFn: (row) => new Date(row.due), //convert to Date for sorting and filtering
      },

      generateColumn('p'),
      generateColumn('a'),
      generateColumn('l'),
      generateColumn('f'),
      {
        accessorKey: "hrl",
        header: "hrl",
        multiline: true,
        Cell: ({ cell }) => (
          <span style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }} >
            <div>
              {cell.renderValue()}
            </div>
          </span>
        ),
      },
    ],
    [data]
  );
  const table = useMaterialReactTable({
    columns,
    data: tableData,
    editDisplayMode: 'cell',
    enableEditing: true,
    localization: MRT_language,
    enableGrouping: true,
    initialState: {
      density: "compact",
      pagination: { pageIndex: 0, pageSize: 30 },
      grouping: ['cell'], expanded: true
    },
    //SET EDIT
    muiTableBodyCellProps: ({ cell, column, table }) => ({
      onClick: () => {
        table.setEditingCell(cell);
        console.log("editcell", cell, "column", column, "table", table);
      }
    }),
    renderTopToolbarCustomActions: ({ table }) => (
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Link to={`/ProjectEdit/${"new"}`}>
          <Button disabled={data === undefined} variant="contained">
            Add new Project
          </Button>
        </Link>
      </Box>
    ),
    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
        {
          backgroundColor: darken(baseBackgroundColor, 0.1),
        },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
        {
          backgroundColor: darken(baseBackgroundColor, 0.2),
        },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
        {
          backgroundColor: lighten(baseBackgroundColor, 0.1),
        },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
        {
          backgroundColor: darken(baseBackgroundColor, 0.2),
        },
      }),
    },
    mrtTheme: (theme) => ({
      baseBackgroundColor: baseBackgroundColor,
      draggingBorderColor: theme.palette.secondary.main,
    }),
  });


  // ******************************************** Define Colums for Table *************************************************************
  if (error) {
    return (
      <div>
        <p>Error: {error.message}</p>
        {error.graphQLErrors.map((err, index) => (
          <p key={index}>GraphQL error: {err.message}</p>
        ))}
        {error.networkError && <p>Network error: {error.networkError.message}</p>}
      </div>
    );
  }

  if (loading) {
    return (
      <div className="spinner">
        <MetroSpinner size={80} color="black" loading={loading} />
      </div>
    );
  }

  return (
    <div>
      <Box sx={{ mt: 2, ml: 1, mr: 1 }}>
        <MaterialReactTable table={table} />
      </Box>
    </div>
  );


};

export default GetProject;
