import Root from './routes/Root';import * as React from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  ApolloProvider
} from "@apollo/client";
import './config/i18n'
import {client} from './config/apollo' 
import './App.css';
import './config/stabstool.css'
import ErrorPage from "./components/ErrorPage";
import LandingPage from "./routes/LandingPage";
import AdminPage from "./routes/AdminPage";
import AboutPage from "./routes/AboutPage";



//Journal Redirection
import JournalIdPage, {loader as journalLoader} from "./routes/JournalIdPage"
// Taskllist Redirection
import TasklistIdPage , {loader as Tasklistloader} from "./routes/TasklistIdPage"
//Consequence Redirection
import ConsequencesIdPage , {loader as Consequencesloader} from "./routes/consequencesIdPage";

import PromanagerPage  from "./routes/PromanagerPage";

import ProjectEditIdPage, { loader as ProjectEditLoader } from "./routes/ProeditfieldIdPage";
import ProcessPage, {loader as ProcessLoader} from 'routes/ProcessPage';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <LandingPage />
      },
      {
        path: "admin",
        element: <AdminPage />,
      },
      {
        path: "journal/:id?", 
        element: <JournalIdPage />,
        loader: journalLoader
      },
      {
        path: "tasklist/:id?",
        element: <TasklistIdPage />,
        loader: Tasklistloader
      },
      {
        path: "consequences/:id?",
        element: <ConsequencesIdPage />,
        loader: Consequencesloader
      },
      {
        path: "process/:id?",
        element: <ProcessPage />,
        loader: ProcessLoader
      },
      {
        path: "Promanager",
        element: <PromanagerPage />,
      },
      {
        path: "about",
        element: <AboutPage />
      },
    {
      path: "/ProjectEdit/:id?", 
      element: <ProjectEditIdPage />,
      loader: ProjectEditLoader
    }
    ]
  },
]);


export default function App() {
  React.useEffect(() => {
    document.title = 'PFT';
  }, []);

  return (
    <ApolloProvider client={client}>
      <RouterProvider router={router} />
    </ApolloProvider>
  );
}