import React from 'react';

const HighlightedCell = ({ cell, column }) => {
  const filterValue = column.getFilterValue();
  const cellValue = cell.getValue();

  const highlightText = (text, filter) => {
    if (!filter || !text) {
      return text;
    }

    const regex = new RegExp(`(${filter})`, 'gi');
    const parts = text.split(regex);

    return parts.map((part, index) => {
      if (part.toLowerCase() === filter.toLowerCase()) {
        return (
          <span key={index} style={{ backgroundColor: 'yellow' }}>
            {part}
          </span>
        );
      }
      return part;
    });
  };

  return <>{highlightText(cellValue, filterValue)}</>;
};

export default HighlightedCell;