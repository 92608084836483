import ExcelJS from 'exceljs';

const exportExcelFile = (data, columns, fileName, t) => {
  // Create a new workbook
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet(fileName, {
    pageSetup: {
      horizontalCentered: true,
      paperSize: 9,
      orientation: "landscape",
      margins: {
        left: 0.3149606,
        right: 0.3149606,
        top: 0.3543307,
        bottom: 0.3543307,
        header: 0.3149606,
        footer: 0.3149606,
      },
    },
  });

  // Add columns to the worksheet
  sheet.columns = columns.map(column => ({
    header: column.header,
    key: column.key,
    width: column.width,
  }));



  sheet.getRow(1).height = 30;
  sheet.pageSetup.printTitlesColumn = "1:1";
  sheet.autoFilter = {
    from: "A1",
    to: "J1",
  };

  // Define Border of Row 1 Header
  sheet.getRow(1).eachCell({ includeEmpty: false }, function (cell) {
    cell.border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
  });

  // Add data to the worksheet
  data.forEach(entry => {
    sheet.addRow(entry);
  });

  // Apply styles to the columns
  columns.forEach((column, index) => {
    if (column.style) {
      sheet.getColumn(index + 1).alignment = column.style.alignment;
    }
  });

  // Generate the Excel file and trigger the download
  workbook.xlsx.writeBuffer().then(buffer => {
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${fileName}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};

export default exportExcelFile;