import { Stack, Typography, Button } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useTranslation } from "react-i18next";
import axios from 'axios';


export default function ImportTab() {
    const { t } = useTranslation("admin");

    const handleFileUpload = (event) => {
        console.log(event);
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file);

        let link = "/exportimport/import";
        if (process.env.NODE_ENV === 'development') {
            link = "https://localhost:8080/exportimport/import" ;
        }

        axios.post(link, file, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.error(error);
            });
    };

    return (
        <Stack className="content" spacing={2} sx={{ maxWidth: "50ch" }}>
            <Typography variant="h2">{t("importTabTitle")}</Typography>
            <p>{t("selectImportFile")}</p>
            <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<UploadFileIcon />}
            >
                {t("UploadFile")}
                <input
                    type="file"
                    accept=".json"
                    hidden
                    onChange={handleFileUpload}
                />
            </Button>
        </Stack>
    );
}