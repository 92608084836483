import { Box, Stack } from "@mui/material";
import { useState } from "react";
import OperationsSelector from "components/OperationsSelector";
import ProcessTab from "./ProcessTab";
import ProcessSVG from "./ProcessSVG";
import FileBrowserStack from "./FileBrowserStack";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";


export default function Processes(props) {
    const [selectedOp, setSelectedOp] = useState("");
    const [selectedProcess, setSelectedProcess] = useState("");
    const [folderviews, setFolderviews] = useState([]);
    const navigate = useNavigate();

    if (props.id) {
        if(selectedOp.id == null) setSelectedOp({id:props.id});
    }

    useEffect(() => {
        if (selectedOp && selectedOp.id) {
            window.journalid = selectedOp.id;
            window.name = selectedOp.name;
            document.title = `process ${selectedOp.name}`;
            navigate(`/process/${selectedOp.id}`);
        }
    }, [selectedOp, navigate]);

    function updateFolderviews(newFV) {
        if (JSON.stringify(newFV) !== JSON.stringify(folderviews)) setFolderviews(newFV);
    }

    return (
        <Stack direction={"column"} margin={"1EM"} spacing={"1EM"}>
            <Stack direction={"row"} spacing={"1EM"} >
                <Box minWidth={"30EM"}>
                    <OperationsSelector
                        filterState={["CLOSED"]}
                        onSetOperation={(op) => setSelectedOp(op)}
                        value={!(selectedOp === null) ? selectedOp.id : ""}
                    />
                </Box>
                {selectedOp &&
                    <ProcessTab op={selectedOp} onSetProcess={(process) => setSelectedProcess(process)} />
                }

            </Stack>
            <Stack direction={"row"} spacing={"1EM"}>
                {selectedProcess && (
                    <ProcessSVG
                        key={selectedOp.id} // Add this to force re-render when selectedOp changes
                        op={selectedOp}
                        process={selectedProcess}
                        onSetFolderviews={updateFolderviews}
                    />
                )}
                {folderviews.length > 0 && (
                    <Box width={"100%"}>
                        <FileBrowserStack
                            folders={folderviews}
                            operation={selectedOp}
                            clientPath={selectedProcess.clientPath}
                        />
                    </Box>
                )}
            </Stack>
        </Stack>
    );
}
