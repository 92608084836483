import React, { useMemo, useState, useCallback } from "react";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { MaterialReactTable, useMaterialReactTable, } from 'material-react-table';
import { MetroSpinner } from "react-spinners-kit";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { Box } from '@mui/material';
import { darken, lighten, useTheme } from '@mui/material';
import { MRT_Localization_FR } from "material-react-table/locales/fr";
import { MRT_Localization_DE } from "material-react-table/locales/de";
import { MRT_Localization_EN } from "material-react-table/locales/en";
//****************** ADD ENTRY ******************************/
import MODIFY_CONTENT from "GraphQL/ModifyJournalContent";
//****************** ADD ENTRY ******************************/

//****************** IMPORT GQL ****************************/
import { MODIFY_ENTRY } from "GraphQL/Journals";
import { LOAD_ENTRIES } from "GraphQL/Journals";
import { MODIFY_JEflwBy } from "GraphQL/Journals";
//****************** IMPORT GQL ****************************/

import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import logo from "pictures/bundlogo.png";
import { useQuery, useMutation } from "@apollo/client";
import jsPDF from "jspdf";
import "jspdf-autotable";
import HighlightedCell from './Highlighttext';
import { useTranslation } from "react-i18next";



const GetEntriesTable = (journal, refresh) => {
  //**************************** DECLARE CONST *****************************************/
  const [idcontent, setidcontent] = useState(null);
  const [selectedContent, setSelectedContent] = useState("");
  const [modJournal] = useMutation(MODIFY_ENTRY);
  const [modContent] = useMutation(MODIFY_CONTENT);
  const [modMJefollowBY] = useMutation(MODIFY_JEflwBy);
  const { t, i18n } = useTranslation("journal");
  const ExcelJS = require("exceljs");
  const [open, setOpen] = useState(false);
  const [selectedCreatedAtDate, setSelectedCreatedAtDate] = useState(null);
  const [selectedFollowupAtDate, setSelectedFollowupAtDate] = useState(null);
  let MRT_language = "";
  const theme = useTheme();

  if (i18n.language === "fr") {
    MRT_language = MRT_Localization_FR;
  }
  if (i18n.language === "de") {
    MRT_language = MRT_Localization_DE;
  }
  if (i18n.language === "en") {
    MRT_language = MRT_Localization_EN;
  }

  const { loading, error, data } = useQuery(LOAD_ENTRIES, {
    variables: { journal: journal.journal },
    pollInterval: journal.refresh * 1000,
  });

  const baseBackgroundColor =
    theme.palette.mode === 'dark'
      ? 'rgba(240, 240, 240, 1)' // Very light gray for dark mode
      : 'rgba(255, 255, 255, 1)'; // White for light mode

  function formatDate(string) {
    if (!string) {
      return "";
    }
    var options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return new Date(string).toLocaleDateString([], options);
  }


  const handleContentClick = useCallback((row) => {
    setSelectedContent(row.original.content);
    setidcontent(row.original.id);
    setOpen(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveCell = useCallback((cell, value) => {
    if (!data || !data.getJournalEntryByJournalId) {
      console.error("Data is undefined ", data, cell, value);
      return;
    }
    const { row, column } = cell;
    const entry = data.getJournalEntryByJournalId[row.index];
    const iddata = entry.id;

    //*** SET CONTENT ***/
    let contentdata = column.id === "content" ? value : entry.content;

    //*** SET CREATEDBYNAME ***/
    let createdBynamedata = column.id === "createdBy.name" ? value : entry.createdBy.name;

    //*** SET FOLLOWUPAT ***/
    const datefollow = new Date();
    let followat =
      datefollow.getFullYear() +
      "-" +
      String(datefollow.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(datefollow.getDate()).padStart(2, "0") +
      "T" +
      String(datefollow.getHours()).padStart(2, "0") +
      ":" +
      String(datefollow.getMinutes()).padStart(2, "0") +
      ":" +
      String(datefollow.getSeconds()).padStart(2, "0");


    //*** SET FOLLOWUP ***/
    let followupdata = column.id === "followup" ? value : entry.followup;

    //*** SET FOLLOWUPBYNAME ***/
    if (value === "") {
      value = entry.followupBy.name;
    }
    //old co
    // let followupBynamedata =  column.id === "followfollowupBy.name" ? value : entry.followupBy.name;

    if (cell.column.id === "followupBy.name") {

      modMJefollowBY({
        variables: {
          itemid: iddata,
          journalid: journal.journal,
          followupBy: value,
        },
        refetchQueries: [
          { query: LOAD_ENTRIES, variables: { journal: journal.journal } },
        ],
      });
    }

    if (cell.column.id !== "followupByname" && journal.journal !== null) {
      modJournal({
        variables: {
          itemid: iddata,
          journalid: journal.journal,
          content: contentdata,
          createdBy: createdBynamedata,
          // followupBy: followupBynamedata, // Modify the nullable string variable
          followup: followupdata,
          followupAt: followat,
        },
        refetchQueries: [
          { query: LOAD_ENTRIES, variables: { journal: journal.journal } },
        ],
      });
    }
  }, [data, journal.journal, modMJefollowBY, modJournal]);


  const columns = useMemo(
    () => [
      // HEADER
      {
        accessorKey: "createdAt",
        header: t("tablelibrary.creationdate"),
        enableEditing: false,
        style: { whiteSpace: "wrap" },
        enableClickToCopy: false,
        enableColumnFilter: true,
        Cell: ({ cell }) => formatDate(cell.getValue()?.toLocaleString()),
        size: 1,
        filterFn: (row) => {
          if (!selectedCreatedAtDate) return true;
          const date = dayjs(row.original.createdAt);
          const formattedDate = date.format('DD-MM-YYYY');
          const selectedFormattedDate = dayjs(selectedCreatedAtDate).format('DD-MM-YYYY');
          return formattedDate === selectedFormattedDate;
        },
        Filter: ({ column }) => {
          return (
            <DatePicker
              label={t("tablelibrary.creationdate")}
              value={selectedCreatedAtDate}
              onChange={(date) => {
                setSelectedCreatedAtDate(date);
                column.setFilterValue(date ? dayjs(date).format('DD-MM-YYYY') : null);
              }}
              format="DD/MM/YYYY"
            />
          );
        },
        muiTableHeadCellProps: {
          title: t("tablelibrary.creationdate"),
        },
      },
      {
        accessorKey: 'createdBy.name',
        header: (t("tablelibrary.creator")),
        Cell: ({ cell, column }) => {
          return (
            <HighlightedCell cell={cell} column={column} />
          );
        },
        size: 30,
        muiEditTextFieldProps: ({ cell, row, table }) => ({
          onBlur: (event) => {
            handleSaveCell(cell, event.target.value, row, table);
          },
          placeholder: t("tablelibrary.creator") || '',
        }),
        filterVariant: 'text',
        muiTableHeadCellProps: {
          title: t("tablelibrary.creator"),
        },
      },
      {
        accessorKey: "content",
        header: t("tablelibrary.content"),
        enableEditing: false,
        enableClickToCopy: false,
        Cell: ({ cell, row, column }) => (
          <span
            style={{ whiteSpace: "pre-line" }}
            onDoubleClick={() => handleContentClick(row)}
          >
            <HighlightedCell cell={cell} column={column} />
          </span>
        ),
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          variant: "outlined",
          multiline: true,
        }),
        grow: true,
        size: 30,
        muiTableHeadCellProps: {
          title: t("tablelibrary.content"),
        },
      },
      {
        accessorKey: "followupBy.name",
        header: t("tablelibrary.followupBy"),
        Cell: ({ cell, column }) => (
          <span style={{ whiteSpace: "pre-line" }}>
            <HighlightedCell cell={cell} column={column} />
          </span>
        ),
        muiEditTextFieldProps: ({ cell, row, table }) => ({
          onBlur: (event) => {
            handleSaveCell(cell, event.target.value, row, table);
          },
        }),
        muiTableHeadCellProps: {
          title: t("tablelibrary.followupBy"),
        },
      },
      {
        accessorKey: "followup",
        header: t("tablelibrary.followup"),
        Cell: ({ cell, column }) => (
          <span style={{ whiteSpace: "pre-line" }}>
            <HighlightedCell cell={cell} column={column} />
          </span>
        ),
        muiEditTextFieldProps: ({ cell, row, table }) => ({
          onBlur: (event) => {
            handleSaveCell(cell, event.target.value, row, table);
          },
        }),
        muiTableHeadCellProps: {
          title: t("tablelibrary.followup"),
        },
      },
      {
        accessorKey: "followupAt",
        header: t("tablelibrary.FollowTime"),
        enableClickToCopy: false,
        enableEditing: true,
        Cell: ({ cell }) => formatDate(cell.getValue()?.toLocaleString()),
        size: 2,
        filterFn: (row) => {
          if (!selectedFollowupAtDate) return true;
          const date = dayjs(row.original.followupAt);
          const formattedDate = date.format('DD-MM-YYYY');
          const selectedFormattedDate = dayjs(selectedFollowupAtDate).format('DD-MM-YYYY');
          return formattedDate === selectedFormattedDate;
        },
        Filter: ({ column }) => {
          return (
            <DatePicker
              label={t("tablelibrary.FollowTime")}
              value={selectedFollowupAtDate}
              onChange={(date) => {
                setSelectedFollowupAtDate(date);
                column.setFilterValue(date ? dayjs(date).format('DD-MM-YYYY') : null);
              }}
              format="DD/MM/YYYY"
            />
          );
        },
        muiTableHeadCellProps: {
          title: t("tablelibrary.FollowTime"),
        },
      },
      {
        accessorKey: "lastUpdate",
        header: t("tablelibrary.lastupdate"),
        enableEditing: false,
        enableClickToCopy: false,
        enableColumnFilter: false,
        Cell: ({ cell }) => formatDate(cell.getValue()?.toLocaleString()),
        size: 2,
        muiTableHeadCellProps: {
          title: t("tablelibrary.lastupdate"),
        },
      },
    ],
    [t, handleSaveCell, handleContentClick, selectedCreatedAtDate, selectedFollowupAtDate]
  );


  const handleExport = () => {
    const dataforexecel = data.getJournalEntryByJournalId.map(entry => ({
      id: entry.id,
      content: entry.content,
      creationDate: formatDate(entry.createdAt),
      createdByname: entry.createdBy.name,
      followupByname: entry.followupBy.name,
      followup: entry.followup,
      followupAt: formatDate(entry.followupAt),
      lastUpdate: formatDate(entry.lastUpdate),
    }));

    const columns = [
      { header: "Id", key: "id", width: 4 },
      { header: t("tablelibrary.creationdate"), key: "creationDate", width: 15 },
      { header: t("tablelibrary.content"), key: "content", width: 18, style: { alignment: { wrapText: true } } },
      { header: t("tablelibrary.creator"), key: "createdBy.name", width: 18 },
      { header: t("tablelibrary.followupBy"), key: "followupBy.name", width: 10 },
      { header: t("tablelibrary.followup"), key: "followup", width: 10 },
      { header: t("tablelibrary.followupAt"), key: "followupAt", width: 14, style: { alignment: { wrapText: true } } },
      { header: t("tablelibrary.lastupdate"), key: "lastUpdate", width: 15, style: { alignment: { wrapText: true } } },
    ];

    const file = window.$name + ".xlsx";

    exportExcelFile(dataforexecel, columns, file, t);
  };

  const tableData = useMemo(() => {
    if (data && data.getJournalEntryByJournalId) {
      return data.getJournalEntryByJournalId;
    }
    return [];
  }, [data]);

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    initialState: { density: "compact", pagination: { pageIndex: 0, pageSize: 30 } },
    editDisplayMode: 'cell',
    enableEditing: true,
    localization: MRT_language,
    enableColumnOrdering: true,
    enableRowNumbers: true,
    rowNumberDisplayMode: 'original',

    renderTopToolbarCustomActions: ({ table }) => (
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Button
          onClick={handleExport}
          startIcon={<FileDownloadIcon />}
          variant="contained"
          size="small"
        >
          {t("tablelibrary.btnexport2xlsx")}
        </Button>
        <Button
          onClick={() => {
            const formattedData = [
              ...data.getJournalEntryByJournalId.map((el, index) => [
                index + 1,
                formatDate(el.createdAt),
                el.createdBy.name,
                el.content,
                el.followupBy.name,
                el.followup,
                formatDate(el.followupAt),
                formatDate(el.lastUpdate)
              ])
            ];

            downloadPdf(formattedData);
          }}
          startIcon={<FileDownloadIcon />}
          variant="contained"
          size="small"
        >
          {t("tablelibrary.btnexport2pdf")}
        </Button>
        <Button
          onClick={() => table.resetColumnFilters()}
          startIcon={<FilterAltOffIcon />}
          variant="contained"
          size="small"
        >
          {t("tablelibrary.tclearfilter")}
        </Button>
      </Box>
    ),
    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
        {
          backgroundColor: darken(baseBackgroundColor, 0.1),
        },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
        {
          backgroundColor: darken(baseBackgroundColor, 0.2),
        },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
        {
          backgroundColor: lighten(baseBackgroundColor, 0.1),
        },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
        {
          backgroundColor: darken(baseBackgroundColor, 0.2),
        },
      }),
    },
    renderBottomToolbarCustomActions: () => (
      <Box
        sx={{
          textAlign: "left",
          width: "70%",
          padding: "1px",
          fontSize: "10px",
          fontWeight: "bold",
          color: "gray",
        }}
      >
        {t("tablelibrary.dblclick2edit")}
      </Box>
    ),
    mrtTheme: (theme) => ({
      baseBackgroundColor: baseBackgroundColor,
      draggingBorderColor: theme.palette.secondary.main,
    }),
  });


  if (!data || !data.getJournalEntryByJournalId || data.getJournalEntryByJournalId.length === 0) {
    return <div></div>; // or any other loading indicator
  }


  //**************************** HANDLE MODAL *****************************************/

  const handleSaveChanges = () => {
    modContent({
      variables: {
        itemid: idcontent,
        journalid: window.journalid,
        content: selectedContent,
      },
      refetchQueries: [
        {
          query: LOAD_ENTRIES,
          refetchQueries: [
            {
              query: LOAD_ENTRIES,
              variables: { journal: journal.journal },
            },
          ],
        },
      ],
    });
    //close();
    setOpen(false);
  };
  //**************************** HANDLE MODAL *****************************************/

  //******************************** Export to PDF  *****************************************************************/
  const downloadPdf = (data) => {
    const doc = new jsPDF("l", "pt", "a4");
    const img = new Image();
    img.src = logo;

    doc.setFontSize(7.5);
    doc.text(window.$name, doc.internal.pageSize.getWidth() / 2, 35, null, null, "center");
    doc.addImage(img, "png", 2, 2, 200, 50);
    doc.text(t("getjournals.headerdepartemenrt1"), 600, 15);
    doc.text(t("getjournals.headerdepartemenrt2"), 600, 27);
    doc.text(t("getjournals.headerarmee"), 600, 39);

    doc.autoTable({
      theme: "striped",
      showHead: "everyPage",
      margin: { top: 60, right: 14, bottom: 50, left: 14 },
      cellWidth: "wrap",
      columnStyles: {
        0: { cellWidth: 23 },
        1: { cellWidth: 80 },
        2: { cellWidth: 140 },
        3: { cellWidth: 160 },
        4: { cellWidth: 70 },
        5: { cellWidth: 160 },
        6: { cellWidth: 70 },
        7: { cellWidth: 85 },
        8: { cellWidth: 95 },
      },
      head: [
        [
          { content: "ID", styles: { halign: "center", fillColor: [0, 0, 255], textColor: [255, 255, 255], fontStyle: "bold", fontSize: 10 } },
          { content: t("tablelibrary.creationdate"), styles: { halign: "left", fillColor: [0, 0, 255], textColor: [255, 255, 255], fontStyle: "bold", fontSize: 10 } },
          { content: t("tablelibrary.creator"), styles: { halign: "left", fillColor: [0, 0, 255], textColor: [255, 255, 255], fontStyle: "bold", fontSize: 10 } },
          { content: t("tablelibrary.content"), styles: { halign: "left", fillColor: [0, 0, 255], textColor: [255, 255, 255], fontStyle: "bold", fontSize: 10 } },
          { content: t("tablelibrary.followupBy"), styles: { halign: "left", fillColor: [0, 0, 255], textColor: [255, 255, 255], fontStyle: "bold", fontSize: 10 } },
          { content: t("tablelibrary.followup"), styles: { halign: "left", fillColor: [0, 0, 255], textColor: [255, 255, 255], fontStyle: "bold", fontSize: 10 } },
          { content: t("tablelibrary.FollowTime"), styles: { halign: "center", fillColor: [0, 0, 255], textColor: [255, 255, 255], fontStyle: "bold", fontSize: 10 } },
          { content: t("tablelibrary.lastupdate"), styles: { halign: "center", fillColor: [0, 0, 255], textColor: [255, 255, 255], fontStyle: "bold", fontSize: 10 } },
        ]
      ],
      body: data,
    });

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 0; i < pageCount; i++) {
      doc.setPage(i);
      const pageCurrent = doc.internal.getCurrentPageInfo().pageNumber;
      doc.text(`${t("tablelibrary.page")} ${pageCurrent}/${pageCount}`, 20, 550);
    }

    doc.save(`${window.$name}.pdf`);
  };

  //******************************** Export to PDF  *****************************************************************/



  //************************ NEW EXPORT CSV **************************************** */



  const exportExcelFile = (dataforexcel) => {
    const workbook = new ExcelJS.Workbook();

    var sheet = workbook.addWorksheet(window.$name, {
      pageSetup: {
        horizontalCentered: true,
        paperSize: 9,
        orientation: "landscape",
        margins: {
          left: 0.3,
          right: 0.3,
          top: 0.3,
          bottom: 0.3,
          header: 0.3,
          footer: 0.2,
        },
      },
    });
    sheet.getRow(1).height = 30;
    sheet.pageSetup.printTitlesColumn = "1:1";
    sheet.autoFilter = {
      from: "A1",
      to: "H1",
    };

    sheet.headerFooter.oddFooter = "Page &P of &N";

    const columnsStyle = { font: { name: "Arial Black", size: 10 } };

    //Define Headers Colums and set Backgroundcolor
    ["A1", "B1", "C1", "D1", "E1", "F1", "G1", "H1"].forEach((key) => {
      const cell = sheet.getCell(key);
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "00000000" },
        bgColor: { argb: "96C8FB" },
      };
    });
    //Define fonc option
    sheet.getRow(1).font = {
      name: "Arial",
      family: 4,
      size: 13,
      bold: true,
      font: { color: { argb: "#FFFFFF" } },
      color: { argb: "FFFFFF" },
    };

    sheet.columns = [
      {
        header: "Id",
        key: "id",
        width: 4,
      },
      {
        header: t("tablelibrary.creationdate"),
        key: "creationDate",
        width: 15,
      },
      {
        header: t("tablelibrary.creator"),
        key: "createdByname",
        width: 18,
      },
      {
        header: t("tablelibrary.content"),
        key: "content",
        width: 27,
        style: {
          name: "PO#",
          style: columnsStyle,
          alignment: { wrapText: true },
        },
      },
      {
        header: t("tablelibrary.followupBy"),
        key: "followupByname",
        width: 15,
      },
      {
        header: t("tablelibrary.followup"),
        key: "followup",
        width: 25,
      },
      {
        header: t("tablelibrary.FollowTime"),
        key: "followupAt",
        width: 14,
      },
      {
        header: t("tablelibrary.lastupdate"),
        key: "lastUpdate",
        width: 15,
        style: {
          name: "PO#",
          style: columnsStyle,
          alignment: { wrapText: true },
        },
      },
    ];

    dataforexcel.forEach((element) => {
      sheet.addRow({
        id: element.id,
        content: element.content,
        creationDate: element.creationDate,
        createdByname: element.createdByname,
        followup: element.followup,
        followupAt: element.followupAt,
        followupByname: element.followupByname,
        lastUpdate: element.lastUpdate,
      });
    });

    //Generate the Xlsx file
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = window.$name + ".xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };


  //********************************* EXPORT CSV **************************************************************************/
  if (error) {
    console.log({ error });
  }
  if (loading) {
    return (
      <div className="spinner">
        <MetroSpinner size={80} color="black" loading={loading} />
      </div>
    );
  }




  console.log("data", data);
  if (data) {




    return (
      <div>
        <Box sx={{ mt: 2, ml: 1, mr: 1 }}>
          <MaterialReactTable table={table} />
        </Box>
        <Dialog open={open} onClose={handleClose} fullWidth>
          <DialogTitle>{t("tablelibrary.modal.editcontent")}</DialogTitle>
          <DialogContent>
            <TextField
              label={t("tablelibrary.modal.content")}
              value={selectedContent}
              onChange={(e) => setSelectedContent(e.target.value)}
              multiline
              minRows={5}
              maxRows={6}
              fullWidth
              variant="filled"
              required
              size="small"
            />
            <Button
              onClick={() => setSelectedContent(t("addentry.modal.aeiou"))}
              variant="contained"
              sx={{ width: '20%' }}
              size="small"
            >
              {t("addentry.modal.btnaeiou")}
            </Button>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t("addentry.modal.btncancel")}</Button>
            <Button onClick={handleSaveChanges} variant="contained">{t("addentry.modal.btnsave")}</Button>
          </DialogActions>
        </Dialog>
      </div>
    );


  };




};

export default GetEntriesTable;

